const POSITIONS = {
  LOCAL_ADMINISTRATOR: 'LOCAL_ADMINISTRATOR',
  BUSINESS_PROCESS: 'BUSINESS_PROCESS',
  CLIENT: 'CLIENT',
  CONTROL_TOWER: 'CONTROL_TOWER',
  COORDINATOR: 'COORDINATOR',
  KAM: 'KAM',
  LOCAL_BUSINESS_MANAGER: 'LOCAL_BUSINESS_MANAGER',
  REGIONAL_ADMINISTRATOR: 'REGIONAL_ADMINISTRATOR',
};

const TASKER_PERMISSIONS = {
  LOOK_TASKER_LIST: 'LOOK_TASKER_LIST',
  FILTER_TASKER_LIST: 'FILTER_TASKER_LIST',
  EXPORT_TASKER_LIST: 'EXPORT_TASKER_LIST',
  TM_SEND_VERIFICATION_EMAIL: 'TM_SEND_VERIFICATION_EMAIL',
  UPDATE_TASKER_STATUS: 'UPDATE_TASKER_STATUS',
  LOOK_TASKER_DATA: 'LOOK_TASKER_DATA',
  EDIT_TASKER_DATA: 'EDIT_TASKER_DATA',
  LOOK_TASKER_CONTRACT: 'LOOK_TASKER_CONTRACT',
  EDIT_TASKER_CONTRACT: 'EDIT_TASKER_CONTRACT',
  UPLOAD_TASKER_CONTRACT: 'UPLOAD_TASKER_CONTRACT',
};

const TASK_PERMISSIONS = {
  LOOK_TASK_LIST: 'LOOK_TASK_LIST',
  EXPORT_TASK_LIST: 'EXPORT_TASK_LIST',
  CREATE_TASK_LIST: 'CREATE_TASK_LIST',
  FILTER_TASK_LIST: 'FILTER_TASK_LIST',
  DEACTIVATE_TASK_STATUS: 'DEACTIVATE_TASK_STATUS',
  ACTIVATE_TASK_STATUS: 'ACTIVATE_TASK_STATUS',
  EDIT_TASK_DATA: 'EDIT_TASK_DATA',
  LOOK_TASK_DATA: 'LOOK_TASK_DATA',
  LOOK_MATERIAL_TASK_DATA: 'LOOK_MATERIAL_TASK_DATA',
  EDIT_MATERIAL_TASK_DATA: 'EDIT_MATERIAL_TASK_DATA',
  CREATE_TASK_MATERIAL: 'CREATE_TASK_MATERIAL',
  LOOK_TURN_DATA: 'LOOK_TURN_DATA',
  EDIT_TURN_DATA: 'EDIT_TURN_DATA',
  CREATE_TURN: 'CREATE_TURN',
  FILTER_TURN: 'FILTER_TURN',
  UPDATE_TURN_STATUS: 'UPDATE_TURN_STATUS',
  FILTER_CERTIFICATE_USER_TASK: 'FILTER_CERTIFICATE_USER_TASK',
  LOOK_CERTIFICATE_USER_TASK: 'LOOK_CERTIFICATE_USER_TASK',
  LOOK_SUBSCRIBED_USER_TURN: 'LOOK_SUBSCRIBED_USER_TURN',
  ACCEPT_SUBSCRIBED_USER_TURN: 'ACCEPT_SUBSCRIBED_USER_TURN',
  CONFIRM_SUBSCRIBED_USER_TURN: 'CONFIRM_SUBSCRIBED_USER_TURN',
  CANCEL_SUBSCRIBED_USER_TURN: 'CANCEL_SUBSCRIBED_USER_TURN',
  FILTER_SUBSCRIBED_USER_TURN: 'FILTER_SUBSCRIBED_USER_TURN',
  LOOK_ASISTANCE_LIST_TURN: 'LOOK_ASISTANCE_LIST_TURN',
  FILTER_ASISTANCE_LIST_TURN: 'FILTER_ASISTANCE_LIST_TURN',
};

const PAYMENT_PERMISSIONS = {
  GENERATE_PAYROLL: 'GENERATE_PAYROLL',
  DOWNLOAD_PAYROLL: 'DOWNLOAD_PAYROLL',
  UPLOAD_CUSTOMER_PAYROLL_FILE: 'UPLOAD_CUSTOMER_PAYROLL_FILE',
  UPLOAD_FINAL_PAYROLL: 'UPLOAD_FINAL_PAYROLL',
};

const QR_CODE_PERMISSIONS = { LOOK_QR_CODE: 'LOOK_QR_CODE' };

const USER_PERMISSIONS = {
  CREATE_ADMIN_USER: 'CREATE_ADMIN_USER',
  LOOK_ADMIN_USER: 'LOOK_ADMIN_USER',
  EDIT_ADMIN_USER: 'EDIT_ADMIN_USER',
  LOOK_ALL_TASKS_IN_COUNTRY: 'LOOK_ALL_TASKS_IN_COUNTRY',
  LOOK_ALL_STORES_IN_TASK: 'LOOK_ALL_STORES_IN_TASK',
};

const SETTING_PERMISSIONS = {
  LOOK_STORE_DATA: 'LOOK_STORE_DATA',
  EDIT_STORE_DATA: 'EDIT_STORE_DATA',
  CREATE_STORE: 'CREATE_STORE',
  LOOK_MATERIAL_DATA: 'LOOK_MATERIAL_DATA',
  EDIT_MATERIAL_DATA: 'EDIT_MATERIAL_DATA',
  CREATE_MATERIAL: 'CREATE_MATERIAL',
  LOOK_CERTIFICATION_DATA: 'LOOK_CERTIFICATION_DATA',
  EDIT_CERTIFICATION_DATA: 'EDIT_CERTIFICATION_DATA',
  CREATE_CERTIFICATION: 'CREATE_CERTIFICATION',
  LOOK_COMPANY_DATA: 'LOOK_COMPANY_DATA',
  EDIT_COMPANY_DATA: 'EDIT_COMPANY_DATA',
  CREATE_COMPANY: 'CREATE_COMPANY',
  LOOK_VERTICAL_DATA: 'LOOK_VERTICAL_DATA',
  EDIT_VERTICAL_DATA: 'EDIT_VERTICAL_DATA',
  CREATE_VERTICAL: 'CREATE_VERTICAL',
  LOOK_PAYROLL_DATA: 'LOOK_PAYROLL_DATA',
};

const ROLE_PERMISSIONS = {
  taskers: TASKER_PERMISSIONS,
  tasks: TASK_PERMISSIONS,
  payments: PAYMENT_PERMISSIONS,
  users: USER_PERMISSIONS,
  qrCode: QR_CODE_PERMISSIONS,
  settings: SETTING_PERMISSIONS,
};

const verifyPermissions = (permissions: any, sectionPermission: string) => {
  if (permissions.length > 0) {
    const res = permissions.find(({ id }: any) => id === sectionPermission);
    return res ? true : false;
  }

  return false;
};

export { POSITIONS, ROLE_PERMISSIONS, verifyPermissions };
