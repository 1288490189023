import React from 'react';
import TextInput from 'components/Atoms/TextInput/TextInput';

import './InputNumber.scss';
import ErrorMessage from 'components/Atoms/ErrorMessage/ErrorMessage';

interface InputNumberInterface {
  defaultValue?: number;
  label: string;
  errors?: any;
  name: string;
  onChange: (value: string, field: any) => void;
  placeholder?: string;
  errorClassName?: string;
  disabled?: boolean;
  className?: string;
}

const InputNumber = ({
  defaultValue = 0,
  label,
  errors,
  name,
  onChange,
  placeholder,
  errorClassName = '',
  disabled = false,
  className = '',
}: InputNumberInterface) => {
  return (
    <div className="input-number__main">
      <p>{label}</p>
      <TextInput
        className={className}
        inputProps={{
          step: 1,
          min: 0,
          max: 99999,
          type: 'number',
          inputMode: 'numeric',
          pattern: '[0-9]*',
          required: true,
        }}
        name={name}
        onChange={({ target }) => onChange(target.value, name)}
        placeholder={placeholder}
        type="text"
        defaultValue={`${defaultValue}`}
        disabled={disabled}
      />
      {errors && errors[name] ? (
        <ErrorMessage className={errorClassName} />
      ) : null}
    </div>
  );
};

export default InputNumber;
