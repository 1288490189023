import api from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';

const UsersService = {
  getUserById: async (userId: number): Promise<BffApiResponse> => {
    try {
      const response = await api.get(`/user/find-custom-user/${userId}`);
      const responseData = response.data.data;
      return new BffApiResponse(true, responseData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getUsersList: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(
        `/user/get-users-without-current-user-in-current-country`
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getRolesList: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(`/role`);

      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getPositionsList: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(`/position`);

      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  createUser: async (payload: any): Promise<BffApiResponse> => {
    try {
      const response = await api.post(`/user/create-user`, { ...payload });
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTasksList: async (): Promise<BffApiResponse> => {
    try {
      const response = await api.get(
        `/task/find-with-custom-user-struct-by-current-country`
      );

      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getStoresListByTask: async (
    taskId: string | number
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.get(
        `/store/find-with-custom-user-struct-by-task/${taskId}`
      );

      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateUser: async (userId: number, payload: any): Promise<BffApiResponse> => {
    try {
      const response = await api.put(`/user/update-user/${userId}`, {
        ...payload,
      });
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
};

export default UsersService;
