import storeImg from 'assets/images/settings/stores.svg';
import clientsImg from 'assets/images/settings/clients.svg';
import certificationsImg from 'assets/images/settings/certifications.svg';
import paymentsImg from 'assets/images/settings/payments.svg';
import verticalsImg from 'assets/images/settings/verticals.svg';
import materialsImg from 'assets/images/settings/materials.svg';
import { DataInterface } from 'components/Atoms/Select/Select';
import { StatusOptionsInterface } from 'components/Organisms/Table/Table.utils';
import { ROLE_PERMISSIONS } from 'routes/ProtectedRoutes.utils';

export const downloadBase64 = (
  base64Data: string,
  fileName: string,
  contentType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const SETTINGS_OPTIONS = [
  {
    text: 'Clientes',
    imgUrl: clientsImg,
    path: 'clients',
    id: ROLE_PERMISSIONS.settings.LOOK_COMPANY_DATA,
  },
  {
    text: 'Tiendas',
    imgUrl: storeImg,
    path: 'stores',
    id: ROLE_PERMISSIONS.settings.LOOK_STORE_DATA,
  },
  {
    text: 'Materiales',
    imgUrl: materialsImg,
    path: 'materials',
    id: ROLE_PERMISSIONS.settings.LOOK_MATERIAL_DATA,
  },
  {
    text: 'Certificaciones',
    imgUrl: certificationsImg,
    path: 'certifications',
    id: ROLE_PERMISSIONS.settings.LOOK_CERTIFICATION_DATA,
  },
  {
    text: 'Vertical',
    imgUrl: verticalsImg,
    path: 'verticals',
    id: ROLE_PERMISSIONS.settings.LOOK_VERTICAL_DATA,
  },
  {
    text: 'Planillas de pago',
    imgUrl: paymentsImg,
    path: 'payment-schedule',
    id: ROLE_PERMISSIONS.settings.LOOK_PAYROLL_DATA,
  },
];

export const MAIN_COLOR = '#00AA80';

const CO_RUT_TYPES = [
  {
    text: 'Cédula de ciudadanía',
    value: 'cc',
  },
  {
    text: 'Cédula extranjería',
    value: 'ce',
  },
];

const PE_RUT_TYPES = [
  {
    text: 'Ninguno',
    value: 'n/a',
  },
  {
    text: 'CE',
    value: 'ce',
  },
  {
    text: 'DNI',
    value: 'dni',
  },
  {
    text: 'Pasaporte',
    value: 'passport',
  },
  {
    text: 'RUC',
    value: 'ruc',
  },
  {
    text: 'Carnet de refugio',
    value: 'refuge_card',
  },
];

export const AVAILABLES_COUNTRIES = [
  {
    name: 'Chile',
    iso: 'es_CL',
  },
  {
    name: 'Peru',
    iso: 'es_PE',
  },
  {
    name: 'Colombia',
    iso: 'es_CO',
  },
  {
    name: 'United States',
    iso: 'en_US',
    extra: 'es_US',
  },
  {
    name: 'Mexico',
    iso: 'es_MX',
  },
  {
    name: 'Others',
    iso: 'none',
  },
];

export const capitalize = (word: string) => {
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
};

export const getRutTypes = (country: string) => {
  if (country === 'PE') return PE_RUT_TYPES;
  return CO_RUT_TYPES;
};

export const getCurrencySymbol = (country: string) => {
  if (country === 'PE') return 'S/';
  return '$';
};

export const getPhone = (
  setPhoneValidation: any,
  language?: string
): string => {
  let indicative = '+56';
  const lan = language ? language : 'es_CL';
  switch (lan) {
    case 'es_PE':
      indicative = '+51';
      setPhoneValidation({
        indicative: 51,
        size: 9,
      });
      break;
    case 'es_CO':
      indicative = '+57';
      setPhoneValidation({
        indicative: 57,
        size: 10,
      });
      break;
    case 'es_MX':
      indicative = '+52';
      setPhoneValidation({
        indicative: 52,
        size: 10,
      });
      break;
    case 'es_US':
    case 'en_US':
      indicative = '+1';
      setPhoneValidation({
        indicative: 1,
        size: 10,
      });
      break;
    case 'es_CL':
    default:
      indicative = '+56';
      setPhoneValidation({
        indicative: 56,
        size: 9,
      });
      break;
  }

  return indicative;
};

export const getCountryCode = (
  isoCode: string,
  returnIso3166 = false
): string => {
  let countryCode = '';

  AVAILABLES_COUNTRIES.forEach(({ iso, extra }) => {
    if (iso === isoCode) {
      countryCode = iso.split('_')[1];
    } else if (isoCode === extra) {
      countryCode = extra.split('_')[1];
    }
  });

  if (returnIso3166) {
    switch (countryCode.toUpperCase()) {
      case 'CL':
        return 'CHL';
      case 'CO':
        return 'COL';
      case 'US':
        return 'USA';
      case 'MX':
        return 'MEX';
      case 'PE':
        return 'PER';
      default:
        throw `Country not found (Value: ${countryCode}).`;
    }
  }

  return countryCode;
};

export const VERTICAL_LIST = [
  {
    text: 'Conductor',
    value: 'driver',
  },
  {
    text: 'Multitasker',
    value: 'multitasker',
  },
  {
    text: 'Tiendero',
    value: 'shopper',
  },
  {
    text: 'Operario',
    value: 'Operary',
  },
  {
    text: 'Picker',
    value: 'picker',
  },
];

export const COMPANIES_LIST = [
  {
    text: 'Retail',
    value: 'retail',
  },
  {
    text: 'Cencosud',
    value: 'cencosud',
  },
];

export const STATES_LIST = [
  {
    text: 'Metropolitana de Santiago',
    value: 'Metropolitana de Santiago',
  },
  {
    text: 'Afnogasta',
    value: 'Afnogasta',
  },
];

export const COMMUNES_LIST = [
  {
    text: 'La Florida',
    value: 'La Florida',
  },
  {
    text: 'Afnogasta',
    value: 'Afnogasta',
  },
];

export const ACTIVE_STATE = [
  {
    text: 'Activo',
    value: '0',
  },
  {
    text: 'Inactivo',
    value: '1',
  },
];

export const CATEGORIES_LIST = [
  {
    text: 'Material General',
    value: 'Material General',
  },
  {
    text: 'Vehículo',
    value: 'Vehículo',
  },
  {
    text: 'Vestimenta',
    value: 'Vestimenta',
  },
];

export const SUB_CATEGORIES_LIST = [
  {
    text: 'Material General',
    value: 'Material General',
  },
  {
    text: 'Calzado',
    value: 'Calzado',
  },
];

export const statusOptions: StatusOptionsInterface[] = [
  {
    value: '1',
    label: 'Activo',
    backgroundColor: 'purple',
    fontColor: 'purple',
    type: 'filled',
  },
  {
    value: '0',
    label: 'Inactivo',
    backgroundColor: 'red',
    fontColor: 'red',
    type: 'filled',
  },
];

export const statusOptionsInscription: StatusOptionsInterface[] = [
  {
    value: '0',
    label: 'Activo',
    backgroundColor: 'purple',
    fontColor: 'purple',
    type: 'filled',
  },
  {
    value: '1',
    label: 'En Progreso',
    backgroundColor: 'green',
    fontColor: 'green',
    type: 'filled',
  },
  {
    value: '2',
    label: 'Cerrado',
    backgroundColor: 'red',
    fontColor: 'red',
    type: 'filled',
  },
];

export const taskerStatusOptions: StatusOptionsInterface[] = [
  {
    value: '0',
    label: 'Validado',
    backgroundColor: 'green',
    fontColor: 'green',
    type: 'filled',
  },
  {
    value: '1',
    label: 'Registrado',
    backgroundColor: 'purple',
    fontColor: 'purple',
    type: 'filled',
  },
  {
    value: '2',
    label: 'Inhabilitado',
    backgroundColor: 'red',
    fontColor: 'red',
    type: 'filled',
  },
];

export const taskStatusOptions: StatusOptionsInterface[] = [
  {
    value: '0',
    label: 'En Proceso',
    backgroundColor: 'yellow',
    fontColor: 'white',
    type: 'filled',
  },
  {
    value: '1',
    label: 'Cerrado',
    backgroundColor: 'white',
    fontColor: 'red',
    type: 'filled',
  },
];

export const inscribedTaskStatusOptions: StatusOptionsInterface[] = [
  {
    value: '0',
    label: 'Aceptado',
    backgroundColor: 'light-green',
    fontColor: 'green',
    type: 'filled',
  },
  {
    value: '1',
    label: 'Pendiente',
    backgroundColor: 'yellow',
    fontColor: 'white',
    type: 'filled',
  },
  {
    value: '3',
    label: 'Cancelado',
    backgroundColor: 'red',
    fontColor: 'red',
    type: 'filled',
  },
  {
    value: '4',
    label: 'Inscrito',
    backgroundColor: 'purple',
    fontColor: 'purple',
    type: 'filled',
  },
  {
    value: '5',
    label: 'En curso',
    backgroundColor: 'gray',
    fontColor: 'white',
    type: 'filled',
  },
  {
    value: '6',
    label: 'Finalizado',
    backgroundColor: 'green',
    fontColor: 'white',
    type: 'filled',
  },
];

export interface OptionsTableInterface {
  onClick: () => void;
  permissions?: any;
}

export interface ITypesList {
  text: string;
  value: string;
}
export interface AdditionalProperty {
  fieldSchemaId: number;
  value: string | null;
}

export type OnChangeValueType = string | undefined | DataInterface;

// @TODO: DELETE THE NEXTS TYPES AFTER THE COMPLETE INTEGRATION
export const RUT_TYPES = [
  {
    text: 'Cédula de ciudadanía',
    value: 'cc',
  },
  {
    text: 'Cédula extranjería',
    value: 'ce',
  },
];

export const ROLES_TYPES = [
  {
    text: 'KAM',
    value: 'KAM',
  },
  {
    text: 'Torre de control',
    value: 'Torre de control',
  },
  {
    text: 'Super Admin',
    value: 'Super Admin',
  },
  {
    text: 'Administrador',
    value: 'Administrador',
  },
  {
    text: 'Operaciones',
    value: 'Operaciones',
  },
];

export const CHARGE_TYPES = [
  {
    text: 'Administrador Regional',
    value: 'Administrador Regional',
  },
  {
    text: 'Administrador local',
    value: 'Administrador local',
  },
  {
    text: 'Business Manager Local',
    value: 'Business Manager Local',
  },
];

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  KAM: 'KAM',
};

export interface ITypeDocuments {
  countryCode: string;
  description: string | null;
  isActive: boolean;
  key: string;
  nameEn: string;
  nameEs: string;
  regexValidation: string | null;
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const removeSpecialCharacters = (text: string) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36); // Obtener el timestamp actual y convertirlo a base 36
  const randomNum = Math.random().toString(36).substring(2, 5); // Generar un número aleatorio y convertirlo a base 36
  const uniqueId = timestamp + randomNum; // Combinar el timestamp y el número aleatorio

  return uniqueId;
};

export const sanitizeFilename = (inputString: string): string => {
  let sanitizedString = inputString;

  // Reemplaza vocales con tilde por sus formas sin tilde
  sanitizedString = sanitizedString
    .replace(/[áäâà]/g, 'a')
    .replace(/[éëêè]/g, 'e')
    .replace(/[íïîì]/g, 'i')
    .replace(/[óöôò]/g, 'o')
    .replace(/[úüûù]/g, 'u')
    .replace(/[ÁÄÂÀ]/g, 'A')
    .replace(/[ÉËÊÈ]/g, 'E')
    .replace(/[ÍÏÎÌ]/g, 'I')
    .replace(/[ÓÖÔÒ]/g, 'O')
    .replace(/[ÚÜÛÙ]/g, 'U');

  // Reemplaza caracteres especiales o raros con guiones bajos
  sanitizedString = sanitizedString.replace(/[^\w.-]/g, '_');

  // Elimina múltiples guiones bajos consecutivos
  const cleanedString = sanitizedString.replace(/_+/g, '_');

  // Elimina guiones bajos al principio y al final
  return cleanedString.replace(/^_+|_+$/g, '');
};
