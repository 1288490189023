import api, { get, post } from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';
import { tasksListData } from 'data/Tasks/TasksList';

const TurnsService = {
  downloadTurnsByTask: async (id: number): Promise<BffApiResponse> => {
    try {
      /*
                        const response = await api.get(`/taskers/list`);
                    */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  saveTurnByTask: async (payload: any): Promise<BffApiResponse> => {
    try {
      const response = await post(`turn/create`, payload);
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTurnsByTask: async (id: number): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(`/turn/get-by-task/${id}`, {
        page: 1,
        pageSize: 10000,
        stores: [],
        regions: [],
        cities: [],
      });
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getStoresByTask: async (taskId: number): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(
        `/store/find-with-custom-user-struct-by-task/${taskId}`
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTurnStatuses: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/turn-status`);
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTurnsByTaskWithFilters: async (
    taskId: number,
    startDate: string,
    endDate: string,
    currentPage: number,
    pageSize: number,
    stores: number[] = [],
    regions: number[] = [],
    cities: number[] = []
  ): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(`/turn/get-by-task/${taskId}`, {
        startDate,
        endDate,
        page: currentPage,
        pageSize: pageSize,
        stores,
        regions,
        cities,
      });
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTurnById: async (turn_id: any): Promise<BffApiResponse> => {
    try {
      const response = await get(`/turn/find-with-dashboard-format/${turn_id}`);
      const responseData = response.data.data;
      return new BffApiResponse(true, responseData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  importMassiveTurnsByTaskId: async (
    taskId: number,
    file: File
  ): Promise<BffApiResponse> => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await post(
        `/turn/insert-massive-turns-by-task/${taskId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadAttendanceReportByTaskId: async (
    taskId: number,
    startDate: string,
    endDate: string
  ): Promise<BffApiResponse> => {
    try {
      const response = await post(
        `/user-turn/get-user-attendance-report-by-task/${taskId}`,
        {
          startDate,
          endDate,
        },
        { responseType: 'blob' }
      );
      return new BffApiResponse(true, response.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadTemplateToCreateMassiveTurnsByTask: async (
    task_id: number
  ): Promise<BffApiResponse> => {
    try {
      const response = await get(
        `/template-files/template-to-create-massive-turns-by-task/${task_id}`,
        { responseType: 'blob' }
      );
      return new BffApiResponse(true, response.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadRegisteredUsersReportByTask: async (
    taskId: number
  ): Promise<BffApiResponse> => {
    try {
      const response = await get(
        `/turn/get-all-users-enrolled-by-status-by-task/signed-up/${taskId}`,
        { responseType: 'blob' }
      );
      return new BffApiResponse(true, response.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadBulkAcceptedRegisteredUsersInTurns:
    async (): Promise<BffApiResponse> => {
      try {
        const response = await get(
          `/template-files/bulk-accepted-registered-users-in-turns`,
          { responseType: 'blob' }
        );
        return new BffApiResponse(true, response.data);
      } catch (err) {
        return formattingTitaskBffResponseErrors(err);
      }
    },
  bulkAcceptedRegisteredUsersByTask: async (
    taskId: number,
    file: File
  ): Promise<BffApiResponse> => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await post(
        `/turn/bulk-accepted-registered-users-by-task/${taskId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateTurnById: async (
    turn_id: number,
    payload: any
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.put(`/turn/update/${turn_id}`, payload);
      return new BffApiResponse(true, response.data.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  selectedActionByTaskId: async (
    action: string,
    taskId: number
  ): Promise<BffApiResponse> => {
    const response = await api.put(`/turn/${action}/${taskId}`);
    return new BffApiResponse(true, response.data.data);
  },
  activateTurn: async (turnId: number): Promise<BffApiResponse> => {
    const response = await api.put(`/turn/activate/${turnId}`);
    return new BffApiResponse(true, response.data.data);
  },
  deactivateTurn: async (turnId: number): Promise<BffApiResponse> => {
    const response = await api.put(`/turn/deactivate/${turnId}`);
    return new BffApiResponse(true, response.data.data);
  },
};

export default TurnsService;
