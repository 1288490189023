import React, { useState, useEffect } from 'react';
import Select from 'components/Atoms/Select/Select';
import { useNavigate } from 'react-router-dom';
import TextInput from 'components/Atoms/TextInput/TextInput';
import { OnChangeValueType } from 'utils/utils';
import useForm from 'hooks/useForm';
import SwitchOption from 'components/Atoms/Switch/Switch';
import TasksService from '../services/TasksService';
import { Button } from 'components/Atoms/Button/Button';
import {
  ROLE_PERMISSIONS,
  verifyPermissions,
} from 'routes/ProtectedRoutes.utils';
import { toast } from 'react-toastify';
interface PersonalDataFormInterface {
  onSubmit: (e: any) => void;
  taskSelected?: any;
  formId: string;
  permissions: any;
}

const INITIAL_EMPTY_FORM_VALUES: any = {
  client: {
    text: '',
    value: '',
  },
  certification: {
    text: '',
    value: '',
  },
  vertical: {
    text: '',
    value: '',
  },
  kam: {
    text: '',
    value: '',
  },
  control_tower: {
    text: '',
    value: '',
  },
  task_name: '',
};

const TaskDataForm = ({
  onSubmit,
  taskSelected,
  formId,
  permissions,
}: PersonalDataFormInterface) => {
  const [errors, setErrors] = useState(INITIAL_EMPTY_FORM_VALUES);
  const { form, onChange, validFields } = useForm(
    taskSelected
      ? {
          client: {
            text: taskSelected.companyName ?? taskSelected.certificationName,
            value: taskSelected.companyId,
          },
          certification: {
            text: taskSelected.certificationName,
            value: taskSelected.certificationId,
          },
          vertical: {
            text: taskSelected.verticalName,
            value: taskSelected.verticalId,
          },
          kam: {
            text: `${taskSelected.kamUserName} ${taskSelected.kamUserLastname}`,
            value: taskSelected.kamUserId,
          },
          control_tower: {
            text: `${taskSelected.controlTowerUserName} ${taskSelected.controlTowerUserLastname}`,
            value: taskSelected.controlTowerUserId,
          },
          task_name: taskSelected.name,
        }
      : INITIAL_EMPTY_FORM_VALUES
  );
  const disabledFields = false;
  const history = useNavigate();
  const [data, setData] = useState({} as any);
  const [dataCliente, setDataCliente] = useState([]);
  const [dataVerticales, setDataVerticales] = useState([]);
  const [dataCertificaciones, setDataCertificaciones] = useState([]);
  const [dataKam, setDataKam] = useState([]);
  const [dataControlTower, setDataControlTower] = useState([]);
  const [switches, setSwitches] = useState({
    checkingQR: false,
    checkoutQR: false,
    geoCheckin: false,
    geoCheckout: false,
    signed_contract: false,
  });
  const switchOnChange = (switchType: string, checked: boolean) => {
    setSwitches({
      ...switches,
      [switchType]: checked,
    });
  };

  const onSubmitForm = async (e: any) => {
    e.preventDefault();

    const newErrors: any = validFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const sendData = {
        name: form.task_name,
        companyId: form.client.value,
        verticalId: form.vertical.value,
        certificationId: form.certification.value,
        kamUserId: form.kam.value,
        controlTowerUserId: form.control_tower.value,
        checkinQrRequired: switches.checkingQR,
        checkoutQrRequired: switches.checkoutQR,
        signContractRequired: switches.signed_contract,
        geolocationCheckinRequired: switches.geoCheckin,
        geolocationCheckoutRequired: switches.geoCheckout,
        riskLevel: 0,
      };
      let isSuccessfully = false;
      if (taskSelected) {
        const response = await TasksService.updateTaskById(
          taskSelected.id,
          sendData
        );
        if (response.statusCode === 200) {
          isSuccessfully = true;
        }
      } else {
        const responseSaveTask = await TasksService.saveTask(sendData);
        if (responseSaveTask.statusCode === 200) {
          isSuccessfully = true;
        }
      }

      if (isSuccessfully) {
        toast.success('Se actualizaron la tarea correctamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        onSubmit(form);
        history('/tasks');
      } else {
        toast.warn(
          '¡UPS! No se pudo actualizar esta tarea, inténtalo nuevamente por favor',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          }
        );
      }
    }
  };

  const loadDataSelects = async () => {
    const responseCliente = await TasksService.getDataClientes();
    const responseVerticales = await TasksService.getDataVerticales();
    const responseKam = await TasksService.getDataKam();
    const responseControlTower = await TasksService.getDataControlTower();

    if (responseCliente.data.length > 0) {
      setDataCliente(responseCliente.data);
    }
    if (responseVerticales.data.length > 0) {
      setDataVerticales(responseVerticales.data);
    }
    if (responseKam.data.length > 0) {
      setDataKam(responseKam.data);
    }
    if (responseControlTower.data.length > 0) {
      setDataControlTower(responseControlTower.data);
    }

    let valorCliente = null;
    if (responseCliente.data.length > 0) {
      valorCliente = responseCliente.data.find(
        (valor: any) => valor.value === taskSelected?.companyId
      );
    }
    let valorVertical = null;
    if (responseVerticales.data.length > 0) {
      valorVertical = responseVerticales.data.find(
        (valor: any) => valor.value === taskSelected?.verticalId
      );
    }
    let ValorKam = null;
    if (responseKam.data.length > 0) {
      ValorKam = responseKam.data.find(
        (valor: any) => valor.value === taskSelected?.kamUserId
      );
    }

    let valorTower = null;
    if (responseControlTower.data.length > 0) {
      valorTower = responseControlTower.data.find(
        (valor: any) => valor.value === taskSelected?.controlTowerUserId
      );
    }
    loadDataById(taskSelected, {
      valorCliente,
      valorVertical,
      ValorKam,
      valorTower,
    });
  };

  const loadDataCertificaciones = async (value: string) => {
    let valorCertificacion = null;
    const responseCertificaciones = await TasksService.getDataCertifications(
      value
    );
    if (responseCertificaciones.data.length > 0) {
      setDataCertificaciones(responseCertificaciones.data);
    }

    if (taskSelected && taskSelected.id) {
      if (responseCertificaciones.data.length > 0) {
        valorCertificacion = responseCertificaciones.data.find(
          (valor: any) => valor.value === taskSelected.certificationId
        );
      }
    }
    if (valorCertificacion) {
      onChange(valorCertificacion, 'certification');
      setData({
        ...data,
        certification: valorCertificacion,
      });
    }
  };

  const loadDataById = async (taskSelected: any, selectValues: any) => {
    if (selectValues.valorCliente) {
      onChange(selectValues.valorCliente, 'client');
    }
    if (selectValues.valorVertical) {
      onChange(selectValues.valorVertical, 'vertical');
    }
    if (selectValues.ValorKam) {
      onChange(selectValues.ValorKam, 'kam');
    }
    if (selectValues.valorTower) {
      onChange(selectValues.valorTower, 'control_tower');
    }

    setSwitches({
      checkingQR: taskSelected?.checkinQrRequired || false,
      checkoutQR: taskSelected?.checkoutQrRequired || false,
      geoCheckin: taskSelected?.geolocationCheckinRequired || false,
      geoCheckout: taskSelected?.geolocationCheckoutRequired || false,
      signed_contract: taskSelected?.signContractRequired || false,
    });
    setData({
      ...data,
      task_name: taskSelected?.name,
      document_type: selectValues.valorCliente,
      vertical: selectValues.valorVertical,
      kam: selectValues.ValorKam,
      control_tower: selectValues.valorTower,
    });
  };

  useEffect(() => {
    if (form.client) {
      loadDataCertificaciones(form.client.value);
    }
  }, [form.client]);

  useEffect(() => {
    loadDataSelects();
  }, []);

  return (
    <>
      {!taskSelected && (
        <>
          <h2>Datos de la Tarea</h2>
          <p>Completa los campos para esta tarea</p>
        </>
      )}
      <div className="form__main form__main--data">
        {Object.keys(data).length > 0 && (
          <form method="POST" onSubmit={onSubmitForm} id={formId}>
            <div className="fields-group fields-group--cols-2 fields-group--rows-2">
              <div className="field-item">
                <TextInput
                  className="custom__height__general__form__view__task"
                  errors={errors}
                  inputProps={{ required: true }}
                  label={'Nombre de la tarea'}
                  name="task_name"
                  onChange={({ target }) => onChange(target.value, 'task_name')}
                  placeholder={'Escribe el nombre de la tarea'}
                  inputPropsAdornments={{
                    required: true,
                  }}
                  disabled={disabledFields}
                  value={form.task_name}
                />
              </div>
              <>
                <div className="field-item">
                  <Select
                    className="select__style"
                    data={dataCliente}
                    errors={errors}
                    label={'Cliente'}
                    name="document_type"
                    onChange={(_e: any, newValue?: OnChangeValueType) => {
                      if (newValue) onChange(newValue, 'client');
                      else onChange('', 'client');
                    }}
                    placeholder={'Selecciona un Cliente'}
                    required
                    disabled={disabledFields}
                    value={form.client}
                  />
                </div>

                <div className="field-item">
                  <Select
                    className="select__style"
                    data={dataVerticales}
                    errors={errors}
                    label={'Vertical'}
                    name="vertical"
                    onChange={(_e: any, newValue?: OnChangeValueType) => {
                      if (newValue) onChange(newValue, 'vertical');
                      else onChange('', 'vertical');
                    }}
                    placeholder={'Selecciona una vertical'}
                    required
                    disabled={disabledFields}
                    value={form.vertical}
                  />
                </div>

                <div className="field-item">
                  <Select
                    className="select__style"
                    data={dataCertificaciones}
                    errors={errors}
                    label={'Certificación'}
                    name="certification"
                    onChange={(_e: any, newValue?: OnChangeValueType) => {
                      if (newValue) onChange(newValue, 'certification');
                      else onChange('', 'certification');
                    }}
                    placeholder={'Selecciona una certificación'}
                    required
                    disabled={disabledFields}
                    value={form.certification}
                  />
                </div>

                <div className="field-item">
                  <Select
                    className="select__style"
                    data={dataKam}
                    errors={errors}
                    label={'KAM'}
                    name="kam"
                    onChange={(_e: any, newValue?: OnChangeValueType) => {
                      if (newValue) onChange(newValue, 'kam');
                      else onChange('', 'kam');
                    }}
                    placeholder={'Selecciona un KAM'}
                    required
                    disabled={disabledFields}
                    value={form.kam}
                  />
                </div>

                <div className="field-item">
                  <Select
                    className="select__style"
                    data={dataControlTower}
                    errors={errors}
                    label={'Torre de control'}
                    name="control_tower"
                    onChange={(_e: any, newValue?: OnChangeValueType) => {
                      if (newValue) onChange(newValue, 'control_tower');
                      else onChange('', 'control_tower');
                    }}
                    placeholder={'Selecciona una torre de control'}
                    required
                    disabled={disabledFields}
                    value={form.control_tower}
                  />
                </div>
              </>

              {(verifyPermissions(
                permissions,
                ROLE_PERMISSIONS.tasks.EDIT_TASK_DATA
              ) ||
                verifyPermissions(
                  permissions,
                  ROLE_PERMISSIONS.tasks.CREATE_TASK_LIST
                )) && (
                <>
                  <div className="field-item">
                    <SwitchOption
                      checked={switches.checkingQR}
                      label="Checking QR"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        switchOnChange('checkingQR', event.target.checked)
                      }
                      withoutTooltip
                    />
                  </div>
                  <div className="field-item">
                    <SwitchOption
                      checked={switches.geoCheckin}
                      label="Geolocalización Checkin"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        switchOnChange('geoCheckin', event.target.checked)
                      }
                      withoutTooltip
                    />
                  </div>
                  <div className="field-item">
                    <SwitchOption
                      checked={switches.checkoutQR}
                      label="Checkout QR"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        switchOnChange('checkoutQR', event.target.checked)
                      }
                      withoutTooltip
                    />
                  </div>
                  <div className="field-item">
                    <SwitchOption
                      checked={switches.geoCheckout}
                      label="Geolocalización Checkout"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        switchOnChange('geoCheckout', event.target.checked)
                      }
                      withoutTooltip
                    />
                  </div>
                  <div className="field-item">
                    <SwitchOption
                      checked={switches.signed_contract}
                      label="Validar Contrato Firmado"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        switchOnChange('signed_contract', event.target.checked)
                      }
                      withoutTooltip
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default TaskDataForm;
