import api from 'utils/axios';
import BffApiResponse from 'models/BffApiResponse';
import { formattingTitaskBffResponseErrors } from 'utils/axiosResponse';
import { tasksListData } from 'data/Tasks/TasksList';
import moment from 'moment';

const TasksService = {
  getTasksList: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/task/get-by-current-country`);
      if (response.status === 200) {
        objectResponse = response.data?.data?.map((values: any) => {
          return {
            id: values.id,
            name: values.name,
            client: values.companyName,
            vertical: values.verticalName,
            certification: values.certificationName,
            creation_date: moment(values.createdAt).format('DD-MM-YYYY'),
            status: values.taskStatusId === 'IN_PROCESSING' ? 0 : 1,
          };
        });
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getTaskById: async (id: number): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = {};
      const response = await api.get(`/task/${id}`);
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getDataClientes: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/company/get-by-current-country`);
      if (response.status === 200) {
        objectResponse = response.data?.data?.map((values: any) => {
          return {
            value: values.id,
            text: values.name,
          };
        });
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getDataVerticales: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/vertical/get-by-current-country`);
      if (response.status === 200) {
        objectResponse = response.data?.data?.map((values: any) => {
          return {
            value: values.id,
            text: values.title,
          };
        });
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getDataControlTower: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(
        `/user/get-control-tower-users-by-current-country`
      );
      if (response.status === 200) {
        objectResponse = response.data?.data?.map((values: any) => {
          return {
            value: values.id,
            text: `${values.name} ${values.lastname}`,
          };
        });
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getDataKam: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/user/get-kam-users-by-current-country`);
      if (response.status === 200) {
        objectResponse = response.data?.data?.map((values: any) => {
          return {
            value: values.id,
            text: `${values.name} ${values.lastname}`,
          };
        });
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getMaterials: async (): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/material/find-with-dashboard-format`);
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getCertificacionById: async (valor: number): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user-certification/get-certified-users-by-task/${valor}`,
        {
          page: 1,
          pageSize: 1000,
          isSignedContract: true,
          regions: [],
          cities: [],
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getCertificationsByIdWithFilters: async (
    taskId: number,
    currentPage: number,
    pageSize: number,
    startDate: string,
    endDate: string,
    regions?: string[],
    cities?: string[],
    isSignedContract?: boolean
  ): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user-certification/get-certified-users-by-task/${taskId}`,
        {
          startDate,
          endDate,
          page: currentPage,
          pageSize,
          isSignedContract,
          regions,
          cities,
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getInscriptionsByTaskId: async (
    taskId: number,
    startDate: string,
    endDate: string
  ): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user-turn/get-all-users-enrolled-in-turns-by-task-with-registered-format/${taskId}`,
        {
          startDate,
          endDate,
          page: 1,
          pageSize: 10000,
          stores: [],
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getInscriptionsByTaskIdWithFilters: async (
    taskId: number,
    startDate: string,
    endDate: string,
    page: number,
    pageSize: number,
    stores?: number[],
    turnStatusIds?: string[]
  ): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user-turn/get-all-users-enrolled-in-turns-by-task-with-registered-format/${taskId}`,
        {
          startDate,
          endDate,
          page,
          pageSize,
          stores,
          turnStatusIds: turnStatusIds
            ? turnStatusIds?.length === 0
              ? undefined
              : turnStatusIds
            : undefined,
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAssistanceByTaskId: async (
    taskId: number,
    startDate: string,
    endDate: string
  ): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user-turn/get-all-users-subscribed-to-assistance-modality-turns-by-task/${taskId}`,
        {
          startDate,
          endDate,
          page: 1,
          pageSize: 10000,
          stores: [],
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAssistanceByTaskIdWithFilters: async (
    taskId: number,
    startDate: string,
    endDate: string,
    currentPage: number,
    pageSize: number,
    stores?: number[]
  ): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.post(
        `/user-turn/get-all-users-subscribed-to-assistance-modality-turns-by-task/${taskId}`,
        {
          startDate,
          endDate,
          page: currentPage,
          pageSize: pageSize,
          stores,
        }
      );
      if (response.status === 200) {
        objectResponse = response.data?.data;
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getDataCertifications: async (valor: string): Promise<BffApiResponse> => {
    try {
      let objectResponse: any = [];
      const response = await api.get(`/certification/get-by-company/${valor}`);
      if (response.status === 200) {
        objectResponse = response.data?.data?.map((values: any) => {
          return {
            value: values.id,
            text: values.name,
          };
        });
      }
      return new BffApiResponse(true, objectResponse);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  saveTask: async (body: any): Promise<BffApiResponse> => {
    try {
      const response = await api.post(`/task/create`, body);
      return new BffApiResponse(true, response);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  saveMaterialsToTask: async (
    taskId: number,
    body: any
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.post(
        `/task/associate-materials-to-task/${taskId}`,
        {
          materialIds: body,
        }
      );
      return new BffApiResponse(true, response);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadTasksList: async (): Promise<BffApiResponse> => {
    try {
      /*
                    const response = await api.get(`/taskers/list`);
                */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  downloadTurnsByTask: async (id: number): Promise<BffApiResponse> => {
    try {
      /*
                        const response = await api.get(`/taskers/list`);
                    */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getMaterialsByTask: async (id: number): Promise<BffApiResponse> => {
    try {
      const response = await api.get(`/material/find-by-task/${id}`);
      return new BffApiResponse(true, response.data?.data);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getCertificationsByTask: async (id: number): Promise<BffApiResponse> => {
    try {
      /*
                                    const response = await api.get(`/taskers/list`);
                                */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAttendanceByTask: async (id: number): Promise<BffApiResponse> => {
    try {
      /*
                                            const response = await api.get(`/taskers/list`);
                                        */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getInscribedByTask: async (id: number): Promise<BffApiResponse> => {
    try {
      /*
                                              const response = await api.get(`/taskers/list`);
                                          */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  addTask: async (payload: any): Promise<BffApiResponse> => {
    try {
      /*
                                                    const response = await api.post(`/taskers/list`, { payload });
                                                    const responseData = response.data.data;
                                                */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  importTaskersByTask: async (payload: any): Promise<BffApiResponse> => {
    try {
      /*
                                                        const response = await api.post(`/taskers/list`, { payload });
                                                        const responseData = response.data.data;
                                                    */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAttendanceCheckIn: async (attendance_id: any): Promise<BffApiResponse> => {
    try {
      /*
                                                            const response = await api.post(`/taskers/list`, { payload });
                                                            const responseData = response.data.data;
                                                        */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getLocationCheckIn: async (attendance_id: any): Promise<BffApiResponse> => {
    try {
      /*
                                                                const response = await api.post(`/taskers/list`, { payload });
                                                                const responseData = response.data.data;
                                                            */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getAttendanceCheckOut: async (
    attendance_id: any
  ): Promise<BffApiResponse> => {
    try {
      /*
                                                                    const response = await api.post(`/taskers/list`, { payload });
                                                                    const responseData = response.data.data;
                                                                */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getLocationCheckOut: async (attendance_id: any): Promise<BffApiResponse> => {
    try {
      /*
                                                                        const response = await api.post(`/taskers/list`, { payload });
                                                                        const responseData = response.data.data;
                                                                    */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateTaskById: async (
    task_id: any,
    payload: any
  ): Promise<BffApiResponse> => {
    try {
      const response = await api.put(`/task/update/${task_id}`, payload);
      const responseData = response.data.data;
      return new BffApiResponse(true, responseData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateMaterialsByTaskId: async (
    task_id: any,
    payload: any
  ): Promise<BffApiResponse> => {
    try {
      /*
                                                                                    const response = await api.post(`/taskers/list`, { payload });
                                                                                    const responseData = response.data.data;
                                                                                */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  updateUserById: async (
    user_id: any,
    payload: any
  ): Promise<BffApiResponse> => {
    try {
      /*
                                                                                            const response = await api.post(`/taskers/list`, { payload });
                                                                                            const responseData = response.data.data;
                                                                                        */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getUserById: async (user_id: number): Promise<BffApiResponse> => {
    try {
      /*
                                                                                                    const response = await api.post(`/taskers/list`, { payload });
                                                                                                    const responseData = response.data.data;
                                                                                                */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
  getStoreById: async (store_id: number): Promise<BffApiResponse> => {
    try {
      /*
                                                                                                        const response = await api.post(`/taskers/list`, { payload });
                                                                                                        const responseData = response.data.data;
                                                                                                    */
      return new BffApiResponse(true, tasksListData);
    } catch (err) {
      return formattingTitaskBffResponseErrors(err);
    }
  },
};

export default TasksService;
